.map--layout.map--layout__map {
  user-select: none;
  flex: 1;
  background: #000;
  .map--wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    #map{
      width: 100%;
      height: 100%;
      background: #ede7d2;
      .leaflet-tooltip-pane, .leaflet-draw-actions.leaflet-draw-actions-bottom,.leaflet-draw-tooltip.leaflet-draw-tooltip-single,.leaflet-draw-tooltip {
        display: none !important;
      }
    }
    .leaflet-container {
      height: 100%;
    }
  }

}

.map--controls {
  position: absolute;
  right: 20px;
  bottom: 120px;
  z-index: 9999;
  .map--controls__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .date--picker {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    select {
      background: #FFF;
      border-radius: 6px;
      border: 1px solid #b5b5b5;
      height: 30px;
      outline: none;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
    }

    .react-datepicker__input-container {
      input {
        height: 30px;
        outline: none;
        appearance: none;
        background: #FFF;
        border-radius: 6px;
        border: 1px solid #b5b5b5;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
        transition: all 0.3s ease 0s;
        font-size: 1rem;
        padding: 0 4px;
        &:focus {
          background: darken(#FFF, 10%);
        }
      }
    }

    button {
      width: 30px;
      height: 30px;
      outline: none;
      cursor: pointer;
      appearance: none;
      background: #FFF;
      border-radius: 6px;
      margin: 0 8px;
      border: 1px solid #b5b5b5;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease 0s;
      &:disabled {
        background: darken(#FFF, 10%);
        cursor: not-allowed;
        pointer-events: all !important;
      }
      svg {
        width: 27px;
        height: auto;
      }
      &.btn-next {
        svg {
          transform: rotate(180deg);
        }
      }
      &:hover {
        background: darken(#FFF, 10%);
      }
      &:active {
        transform: scale(0.8);
      }
    }

    &.legend {
      margin-top: 20px;
      background-color: #FFF;
      flex-direction: column;
      border-radius: 6px;
      padding: 12px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
      .legend--title {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          text-decoration: none;
          color: #111;
          svg {
            width: 30px;
            height: auto;
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}


.leaflet-div-icon {
  background: transparent;
  border: none;
}


.map--point {
  position: relative;
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;

  &.position_left {
    flex-direction: row-reverse;
    .marker--icon.zoomed {
      transform: scale(1.4) translate(5px, 0);
    }
  }
  &.position_right {

    .marker--icon.zoomed {
      transform: scale(1.4) translate(-3px, 0);
    }
  }
  &.position_up {
    flex-direction: column-reverse;
    .marker--icon.zoomed {
      transform: scale(1.4) translate(0, 0px);
    }
  }
  &.position_bottom {
    flex-direction: column;
    .marker--icon.zoomed {
      transform: scale(1.4) translate(0, 0);
    }
  }

  &.hovered {
    z-index: 1111;
    .marker--icon:not(.zoomed) {
      svg {
        #default,#click{
          visibility: hidden;
        }
        #hover {
          visibility: visible;
        }
      }
    }

  }

  .marker--icon {
    position: relative;
    width: 20px;
    height: 20px;
    min-width: 20px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.45));
    transition: all 0.3s ease 0s;
    svg g {
      transition: all 0.3s ease 0s;
    }

    .icon--default, .icon--zoomed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease 0s;
        svg {
          width: 100%;
          height: auto;
        }
    }
    .icon--zoomed {
      opacity: 0;
    }
      &.zoomed {
        .icon--default{
          opacity: 0;
        }
        .icon--zoomed {
          opacity: 1;
        }
      }
    &.active{
      svg g[id="active"], svg g[id="click"] {
        opacity: 1;
      }
      svg g[id="default"] {
        opacity: 0;
      }
    }
  }

}



.marker--text {
  font-size: 0.8rem;
  color: #111;
  direction: rtl;
  font-family: "Noto Sans Arabic", sans-serif;
  transition: all 0.3s ease 0s;
  white-space: pre-wrap;
  line-height: 1;
  opacity: 0;
  &.title_visible {
    opacity: 1;
  }
  &.marker--type_city {
    padding: 4px;
    opacity: 1;
    &.zoom-1 {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
  &.marker--type_sea{
    font-size: 1.2rem;
    color: #a4bcbc;
    opacity: 1;
  }
  &.marker--type_country{
    font-size: 1.5rem;
    color:  rgba(102, 102, 102, 0.6);
    font-weight: 500;
    opacity: 1;
  }
}

.map--popup {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 390px;
  min-width: 200px;
  direction: rtl;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  //z-index: 9999;
  border-radius: 6px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: 10px 20px;
  opacity: 0;
  z-index: -2;
  transition: opacity 0.3s ease 0s;
  &.visible {
    opacity: 1;
    z-index: 9999;
  }

  .map--popup__close {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    transition: all 0.3s ease 0s;
    opacity: 0.7;
    svg {
      width: 100%;
      height: auto;
    }
    &:hover, &:active {
      opacity: 1;
    }
    &:active {
      transform: scale(0.9);
    }

  }
}


.map--popup__content {
  .modal--title {
    font-weight: 600;
    padding-left: 15px;
    font-size: 1rem;
  }
  .modal--text {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 10px;
  }
}

.leaflet-top.leaflet-left {
  top: 0;
  left: auto;
  right: 20px;
}

.leaflet-line {
  stroke: #FF0000 !important;
}