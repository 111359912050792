*{padding: 0;margin:0;box-sizing: border-box}

html,body,#root,.ant-flex {
  width: 100%;
  height: 100%;
}

.header--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    height: 40px;
  }
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}

.content--wrapper {
  max-width: 1024px;
  margin: 20px auto;
}